import React from "react";
import Header from "../../components/header/Header";
const Legals = () => {
  return (
    <div className="container">
      <Header />
      <h5 className="mt-5">1 - Édition du site</h5>
      <p>
        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l'économie numérique, il est précisé aux utilisateurs du
        site internet https://www.pattes-perdues.fr l'identité des différents
        intervenants dans le cadre de sa réalisation et de son suivi:
      </p>
      <p>Propriétaire du site : Mache</p>
      <p>Contact : marion.chedotal@my-digital-school.org 02 61 68 04 40</p>
      <p>Adresse : 1 rue Marie Curie 56890 Plescop.</p>
      <p>Directeur de la publication : Mache</p>
      <p>Contact : 02 61 68 04 40</p>
      <p>
        Hébergeur : PlanetHoster - 4416 Louis B. Mayer, Laval (Québec) H7P 0G1,
        Canada{" "}
      </p>
      <p>Téléphone : +33 1 76 60 41 43</p>
      <p>
        Délégué à la protection des données : Mache -
        marion.chedotal@my-digital-school.org
      </p>
      <p>
        Autres contributeurs : Les crédits photos apparaissent au survol de
        chaque photo.
      </p>

      <h5 className="mt-4">2 - Propriété intellectuelle et contrefaçons.</h5>
      <p>
        Mache est propriétaire des droits de propriété intellectuelle et détient
        les droits d’usage sur tous les éléments accessibles sur le site
        internet, notamment les textes, images, graphismes, logos, vidéos,
        architecture, icônes et sons. Toute reproduction, représentation,
        modification, publication, adaptation de tout ou partie des éléments du
        site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
        autorisation écrite préalable de Mache. Toute exploitation non autorisée
        du site ou de l’un quelconque des éléments qu’il contient sera
        considérée comme constitutive d’une contrefaçon et poursuivie
        conformément aux dispositions des articles L.335-2 et suivants du Code
        de Propriété Intellectuelle.
      </p>

      <h5 className="mt-4">3 - Limitations de responsabilité.</h5>
      <p>
        Mache ne pourra être tenu pour responsable des dommages directs et
        indirects causés au matériel de l’utilisateur, lors de l’accès au site
        https://www.pattes-perdues.fr. Mache décline toute responsabilité quant
        à l’utilisation qui pourrait être faite des informations et contenus
        présents sur https://www.pattes-perdues.fr. Mache s’engage à sécuriser
        au mieux le site https://www.pattes-perdues.fr, cependant sa
        responsabilité ne pourra être mise en cause si des données indésirables
        sont importées et installées sur son site à son insu. Des espaces
        interactifs (espace contact ou commentaires) sont à la disposition des
        utilisateurs. Mache se réserve le droit de supprimer, sans mise en
        demeure préalable, tout contenu déposé dans cet espace qui
        contreviendrait à la législation applicable en France, en particulier
        aux dispositions relatives à la protection des données. Le cas échéant,
        Mache se réserve également la possibilité de mettre en cause la
        responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de
        message à caractère raciste, injurieux, diffamant, ou pornographique,
        quel que soit le support utilisé (texte, photographie …).
      </p>

      <h5 className="mt-4">4 - CNIL et gestion des données personnelles.</h5>
      <p>
        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978
        modifiée, l’utilisateur du site https://www.pattes-perdues.fr dispose
        d’un droit d’accès, de modification et de suppression des informations
        collectées. Pour exercer ce droit, envoyez un message à notre Délégué à
        la Protection des Données : Mache -
        marion.chedotal@my-digital-school.org.
      </p>

      <h5 className="mt-4">5 - Liens hypertextes et cookies</h5>
      <p>
        Le site https://www.pattes-perdues.fr contient des liens hypertextes
        vers d’autres sites et dégage toute responsabilité à propos de ces liens
        externes ou des liens créés par d’autres sites vers
        https://www.pattes-perdues.fr. La navigation sur le site
        https://www.pattes-perdues.fr est susceptible de provoquer
        l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un
        "cookie" est un fichier de petite taille qui enregistre des informations
        relatives à la navigation d’un utilisateur sur un site. Les données
        ainsi obtenues permettent d'obtenir des mesures de fréquentation, par
        exemple. Vous avez la possibilité d’accepter ou de refuser les cookies
        en modifiant les paramètres de votre navigateur. Aucun cookie ne sera
        déposé sans votre consentement. Les cookies sont enregistrés pour une
        durée maximale de 3 mois.
      </p>

      <h5 className="mt-4">6 - Droit applicable et attribution de juridiction.</h5>
      <p>
        Tout litige en relation avec l’utilisation du site
        https://www.pattes-perdues.fr est soumis au droit français. En dehors
        des cas où la loi ne le permet pas, il est fait attribution exclusive de
        juridiction aux tribunaux compétents de Vannes.
      </p>

      <h5 className="mt-4">7 - Politique de confidentialité</h5>
      <p>
        Le site web https://www.pattes-perdues.fr est détenu par Les veilleurs
        de Faune, qui est un contrôleur de données de vos données
        personnelles.Nous avons adopté cette politique de confidentialité, qui
        détermine la manière dont nous traitons les informations collectées par
        https://www.pattes-perdues.fr, qui fournit également les raisons pour
        lesquelles nous devons collecter certaines données personnelles vous
        concernant. Par conséquent, vous devez lire cette politique de
        confidentialité avant d'utiliser le site web de
        https://www.pattes-perdues.fr. Nous prenons soin de vos données
        personnelles et nous nous engageons à en garantir la confidentialité et
        la sécurité.{" "}
      </p>

      <p>Les informations personnelles que nous collectons :</p>
      <p>
        Lorsque vous visitez le https://www.pattes-perdues.fr, nous recueillons
        automatiquement certaines informations sur votre appareil, notamment des
        informations sur votre navigateur web, votre adresse IP, votre fuseau
        horaire et certains des cookies installés sur votre appareil. En outre,
        lorsque vous naviguez sur le site, nous recueillons des informations sur
        les pages web ou les produits individuels que vous consultez, sur les
        sites web ou les termes de recherche qui vous ont renvoyé au site et sur
        la manière dont vous interagissez avec le site. Nous désignons ces
        informations collectées automatiquement par le terme "informations sur
        les appareils". En outre, nous pourrions collecter les données
        personnelles que vous nous fournissez (y compris, mais sans s'y limiter,
        le nom, le prénom, etc.) lors de l'inscription afin de pouvoir exécuter
        le contrat.
      </p>

      <p>Pourquoi traitons-nous vos données ?</p>
      <p>
        Notre priorité absolue est la sécurité des données des clients et, à ce
        titre, nous ne pouvons traiter que des données minimales sur les
        utilisateurs, uniquement dans la mesure où cela est absolument
        nécessaire pour maintenir le site web. Les informations collectées
        automatiquement sont utilisées uniquement pour identifier les cas
        potentiels d'abus et établir des informations statistiques concernant
        l'utilisation du site web. Ces informations statistiques ne sont pas
        autrement agrégées de manière à identifier un utilisateur particulier du
        système. Vous pouvez visiter le site web sans nous dire qui vous êtes ni
        révéler d'informations, par lesquelles quelqu'un pourrait vous
        identifier comme un individu spécifique et identifiable. Toutefois, si
        vous souhaitez utiliser certaines fonctionnalités du site web, ou
        fournir d'autres détails en remplissant un formulaire, vous pouvez nous
        fournir des données personnelles, telles que votre e-mail, votre prénom,
        votre nom, votre ville de résidence, votre organisation, votre numéro de
        téléphone. Vous pouvez choisir de ne pas nous fournir vos données
        personnelles, mais il se peut alors que vous ne puissiez pas profiter de
        certaines fonctionnalités du site web. Par exemple, vous ne pourrez pas
        nous contacter directement à partir du site web. Les utilisateurs qui ne
        savent pas quelles informations sont obligatoires sont invités à nous
        contacter via marion.chedotal@my-digital-school.org.
      </p>

      <p>Vos droits :</p>
      <p>
        Si vous êtes un résident européen, vous disposez des droits suivants
        liés à vos données personnelles : Le droit d'être informé. Le droit
        d'accès. Le droit de rectification. Le droit à l'effacement. Le droit de
        restreindre le traitement. Le droit à la portabilité des données. Le
        droit d'opposition. Les droits relatifs à la prise de décision
        automatisée et au profilage. Si vous souhaitez exercer ce droit,
        veuillez nous contacter via les coordonnées ci-dessous. En outre, si
        vous êtes un résident européen, nous notons que nous traitons vos
        informations afin d'exécuter les contrats que nous pourrions avoir avec
        vous (par exemple, si vous passez une commande par le biais du site), ou
        autrement pour poursuivre nos intérêts commerciaux légitimes énumérés
        ci-dessus. En outre, veuillez noter que vos informations pourraient être
        transférées en dehors de l'Europe, y compris au Canada et aux
        États-Unis.
      </p>

      <p>Sécurité de l'information :</p>
      <p>
        Nous sécurisons les informations que vous fournissez sur des serveurs
        informatiques dans un environnement contrôlé et sécurisé, protégé contre
        tout accès, utilisation ou divulgation non autorisés. Nous conservons
        des garanties administratives, techniques et physiques raisonnables pour
        nous protéger contre tout accès, utilisation, modification et
        divulgation non autorisés des données personnelles sous son contrôle et
        sa garde. Toutefois, aucune transmission de données sur Internet ou sur
        un réseau sans fil ne peut être garantie.
      </p>

      <p>Divulgation légale :</p>
      <p>
        Nous divulguerons toute information que nous collectons, utilisons ou
        recevons si la loi l'exige ou l'autorise, par exemple pour nous
        conformer à une citation à comparaître ou à une procédure judiciaire
        similaire, et lorsque nous pensons de bonne foi que la divulgation est
        nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui,
        enquêter sur une fraude ou répondre à une demande du gouvernement.
      </p>

      <p>Informations de contact :</p>
      <p>
        Si vous souhaitez nous contacter pour comprendre davantage la présente
        politique ou si vous souhaitez nous contacter concernant toute question
        relative aux droits individuels et à vos informations personnelles, vous
        pouvez envoyer un courriel à marion.chedotal@my-digital-school.org.
      </p>
    </div>
  );
};

export default Legals;
